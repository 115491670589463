import React, { Component } from 'react'

import DefaultLayout from '../layouts/default'

function Conditions() {
    return (
        <DefaultLayout>
            
        </DefaultLayout>
    )
}

export default Conditions
import React, { Component } from 'react';
import MyRoutes from './routes'

class App extends Component {
  render() {
    return (
      <MyRoutes/>
    )
  }
}

export default App;
